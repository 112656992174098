import styled from "styled-components";

export const MainHomeContainer = styled.div`
// background: skyblue;
    place-content: space-between;
display: flex;
    // width: 50%;
    // height: 50%;
    padding: 5% 2% 2% 2%;
    // margin: 5% 0% 0% 25%;
    flex-flow: wrap;
    .ImageSize{
    width: 150px;
    height: 125px;
    position: relative;
    margin: 10px 0px 0px 50px !important;
    }
`;