import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MainHomeContainer } from "./CategoryStyle";

const Catogery=()=>{

    return (
        <>
        <h5 style={{textAlign:'center',color:'skyblue'}}>Service Plan</h5>
        
        <MainHomeContainer>
        
<Card style={{ width: '18rem',height:'30%',backgroundColor:'skyblue',marginTop:'2%',backgroundColor:'skyblue' }}>
      <Card.Img className='ImageSize' variant="top" src="https://fireimages.govindmukund.com/TechSol/c1.png" />
      <Card.Body>
        <Card.Title>Design & Arts</Card.Title>
        <Card.Text>
        Welcome to DesignArt, where creativity meets functionality. We specialize in providing unique design solutions that enhance the aesthetic and utility of your home or office. 
        </Card.Text>
        <Button variant="primary" href="https://wa.me/919525621552">Enquery</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem',height:'30%',backgroundColor:'skyblue',marginTop:'2%',backgroundColor:'skyblue' }}>
      <Card.Img className='ImageSize' variant="top" src="https://fireimages.govindmukund.com/TechSol/c2.png" />
      <Card.Body>
        <Card.Title>Web Development</Card.Title>
        <Card.Text>
        we specialize in providing top-notch web development services designed to build engaging, user-friendly websites that elevate your online presence. 
        </Card.Text>
        <Button variant="primary" href="https://wa.me/919525621552">Enquery</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem',height:'30%',backgroundColor:'skyblue',marginTop:'2%',backgroundColor:'skyblue' }}>
      <Card.Img className='ImageSize' variant="top" src="https://fireimages.govindmukund.com/TechSol/c3.png" />
      <Card.Body>
        <Card.Title>SEO Markting</Card.Title>
        <Card.Text>
        we specialize in comprehensive SEO marketing services designed to enhance your website’s visibility, drive organic traffic, and improve your search engine rankings.
        </Card.Text>
        <Button variant="primary" href="https://wa.me/919525621552">Enquery</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem',height:'30%',backgroundColor:'skyblue',marginTop:'2%',backgroundColor:'skyblue' }}>
      <Card.Img className='ImageSize' variant="top" src="https://fireimages.govindmukund.com/TechSol/c4.png" />
      <Card.Body>
        <Card.Title>Video Edting</Card.Title>
        <Card.Text>
        we specialize in professional video editing services that bring your stories to life. Whether for personal projects, promotional materials, or social media content.
        </Card.Text>
        <Button variant="primary" href="https://wa.me/919525621552">Enquery</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem',height:'30%',backgroundColor:'skyblue',marginTop:'2%',backgroundColor:'skyblue' }}>
      <Card.Img className='ImageSize' variant="top" src="https://fireimages.govindmukund.com/TechSol/c5.png" />
      <Card.Body>
        <Card.Title>Logo Design</Card.Title>
        <Card.Text>
        we specialize in creating unique and impactful logo designs that capture the essence of your brand. A well-designed logo is crucial for establishing your identity and making a lasting impression on your audience.
        </Card.Text>
        <Button variant="primary" href="https://wa.me/919525621552">Enquery</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem',height:'30%',backgroundColor:'skyblue',marginTop:'2%',backgroundColor:'skyblue' }}>
      <Card.Img className='ImageSize' variant="top" src="https://fireimages.govindmukund.com/TechSol/c6.png" />
      <Card.Body>
        <Card.Title>Game Design</Card.Title>
        <Card.Text>
        we specialize in creating immersive and engaging game experiences that captivate players. Our team of skilled game designers, artists, and developers work.
        </Card.Text>
        <Button variant="primary" href="https://wa.me/919525621552">Enquery</Button>
      </Card.Body>
    </Card>
        </MainHomeContainer>
        
        </> 
    )
};
export default Catogery;